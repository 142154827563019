import styled from 'styled-components';
import Image from 'next/image';

import getImgSrcWorkaround from '@/utils/images';

const getWidth = (width, height) => {
  return (width * 32) / height;
};

const Companies = ({ list }) => (
  <>
    {list?.map((company) => {
      const width = getWidth(company.attributes.width, company.attributes.height);

      return (
        <Wrapper key={company.attributes.url}>
          <Image
            data-testid="companies-image"
            src={getImgSrcWorkaround(company.attributes.url)}
            alt={company.attributes.alternativeText || ''}
            width={width}
            height={32}
            quality={100}
          />
        </Wrapper>
      );
    })}
  </>
);

const Wrapper = styled.div`
  height: 32px;
  padding-right: 88px;

  img {
    object-fit: contain;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-right: 40px;
  }
`;

export default Companies;
