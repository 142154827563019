'use client';

import {
  Header,
  OurMission,
  OurSolutions,
  Testimonials,
  CustomerValue,
  LearnMore,
  OurDna,
  LookingFor,
  MemberImpact,
  AiCare,
} from '@/components/homepage';
import { CTA, Layout } from '@/components/core';
import TrustedCompanies from '@/components/shared/TrustedCompanies';
import Footnotes from '@/components/shared/footnotes';
import { CtaCounter } from '@/components/shared';

export default function PageContent({
  bannerInfo,
  header,
  trustedCompanies,
  ourMission,
  ourSolutionsContent,
  members,
  testimonials,
  customerValue,
  wantLearnMore,
  ourDna,
  lookingFor,
  footnotes,
  cta,
  ctaCounter,
  aiCare,
}) {
  return (
    <Layout bannerInfo={bannerInfo} dynamicNavbarLayout>
      <Header content={header} />
      {trustedCompanies && <TrustedCompanies content={trustedCompanies} />}
      {ourMission && <OurMission content={ourMission} />}
      {ourSolutionsContent && (
        <OurSolutions content={ourSolutionsContent} spacerTop={!ourMission} />
      )}
      {ctaCounter && <CtaCounter content={ctaCounter} counterType="savings" />}
      {aiCare && <AiCare content={aiCare} />}
      {members && <MemberImpact content={members} />}
      {customerValue && <CustomerValue content={customerValue} />}
      {wantLearnMore && <LearnMore content={wantLearnMore} />}
      {ourDna && <OurDna content={ourDna} />}
      {testimonials && <Testimonials content={testimonials} />}
      {lookingFor && <LookingFor content={lookingFor} />}
      {cta && <CTA {...cta} />}
      {footnotes && <Footnotes footNotes={footnotes} />}
    </Layout>
  );
}
