import styled from 'styled-components';

const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0;
  color: ${(props) => props.theme.colors.grey.dark};
  width: 133px;
  white-space: pre-line;
  opacity: 0.5;
  margin-right: ${({ full }) => (full ? 0 : '50px')};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-right: ${({ full }) => (full ? 0 : '36px')};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    white-space: normal;
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
  }
`;

export default Title;
