import dynamic from 'next/dynamic';

import Wrapper from './Wrapper';
import Title from './Title';
import Companies from './Companies';

const Marquee = dynamic(() => import('./StyledMarquee'), {
  loading: () => <span />,
  ssr: false,
});

const OneWayContainer = dynamic(() => import('./OneWayContainer'), {
  loading: () => <span style={{ height: '35px' }} />,
  ssr: false,
});

const TrustedCompanies = ({ content, white, full }) => {
  if (!content || !content.companies || !content.companies.data.length) return null;

  const { title, companies } = content;

  return (
    <Wrapper white={white} full={full} data-testid="wrapper">
      <OneWayContainer full={full && !title} data-testid="oneWayContainer">
        {title && <Title full={full}>{title}</Title>}

        <Marquee content={companies.data} white={white} full={full}>
          <Companies list={companies.data} />
        </Marquee>
      </OneWayContainer>
    </Wrapper>
  );
};

export default TrustedCompanies;
