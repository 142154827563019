import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 125px;
  overflow: hidden;

  ${(props) =>
    props.full &&
    css`
      height: unset;
    `};

  ${(props) =>
    !props.white &&
    css`
      background-color: ${(props) => props.theme.colors.neutral.default};
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 136px;
    align-items: unset;
    padding-top: 32px;
    ${(props) =>
      props.full &&
      css`
        padding-top: 0;
        height: unset;
      `};
  }
`;

export default Wrapper;
